<template>
  <div id="ourServicesView">
    <SimpleContentBlock
      v-if="simpleContentData"
      :simple-content-data="simpleContentData"
    />
    <ServicesList
      v-if="selectedServices.length"
      :services-list-data="selectedServices"
      @loaded="setPageLoadingFalse"
    />
  </div>
</template>

<script>
import helpers from '@/api/helpers/helpers'
import api from '@/api'
import SimpleContentBlock from '@/components/UI/SimpleContentBlock'
import ServicesList from '@/components/Services/ServicesList'
import { mapMutations } from 'vuex'

export default {
  name: 'OurServices',
  components: { ServicesList, SimpleContentBlock },
  data() {
    return {
      simpleContentData: null,
      servicesListData: [],
    }
  },
  computed: {
    selectedServices() {
      return this.servicesListData.selectedServices || []
    },
  },
  mounted() {
    this.loadOurServices()
  },
  methods: {
    ...mapMutations({
      setPageLoadingFalse: 'setPageLoadingFalse',
    }),
    loadOurServices() {
      this.setOurServicesData(helpers.readStoredResponse('view.our-services'))

      api.views.loadServices().then((response) => {
        this.setOurServicesData(response)
      })
    },
    setOurServicesData(response) {
      if (!response || !response.entry) return

      const entry = response.entry

      this.simpleContentData = entry.simpleContentBlock[0] || null
      this.servicesListData = entry.servicesList[0] || []

      if (
        !this.servicesListData.selectedServices ||
        !this.servicesListData.selectedServices.length
      ) {
        this.setPageLoadingFalse()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#ourServicesView {
  @apply bg-black;
  margin-bottom: 200px;
  padding: 60px 0;
}
</style>
